<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['flushes'])">
      <single-answer-question
        title="label.flushes"
        :value="flushes"
        :options="$options.yesNoOptions"
        dysfunction="skin-redness"
        yes-no
        @input="onFieldChange('flushes', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'FlushingTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    flushes: {
      type: String,
      default: ''
    }
  }
};
</script>
